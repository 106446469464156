define('m14/controllers/system', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

        openFromFile: false,
        displayErrors: true,
        i18n: Ember['default'].inject.service(),

        einschraubtiefeKopfDisabled: true,
        einschraubwinkelDefiniertDisabled: true,
        schraubenabstandDefiniertDisabled: true,

        ttEinschraubtiefeKopf: Ember['default'].computed('i18n.locale', function () {

            return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
        }),
        ttEinschraubwinkelDefiniert: Ember['default'].computed('i18n.locale', function () {

            return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
        }),
        ttSchraubenabstandDefiniert: Ember['default'].computed('i18n.locale', function () {

            return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
        }),

        einschraubtiefe: 2, //anpassen nach array!
        einschraubtiefeKopf: "0.0",
        einschraubwinkel: 45,
        einschraubwinkelDefiniert: "45.0",
        schraubenabstand: 0,
        schraubenabstandDefiniert: "0.0",
        einschraubrichtung: true,

        einschraubtiefen: [],
        eswinkel: [],
        sabstand: [],
        einschraubrichtungen: [],

        showFriction: true,

        init: function init() {
            var self = this;
            this._super();

            var applicationdata = this.controllerFor('application').get('model').application.objectAt(0);

            var lang = applicationdata.get('countrycode');
            var einschraubtiefen = "einschraubtiefen";

            this.set('einschraubtiefen', einschraubtiefenDB.einschraubtiefen_de);
            this.set('einschraubtiefe', 2);

            this.set('eswinkel', einschraubwinkelDB.einschraubwinkel_de);
            this.set('einschraubwinkel', 45);

            this.set('sabstand', schraubenabstandDB.schraubenabstand_de);
            this.set('schraubenabstand', 0);

            this.set('einschraubrichtungen', einschraubrichtungDB.einschraubrichtung_de);
            this.set('schraubenabstand', 0);
        },

        reibungBeruecksichtigen: false, //checkbox
        reibungBeruecksichtigenDisabled: true, //disable-var, disabled=mindestanzahlDisabled in {{ ... }}

        watchReibung: (function () {
            var self = this;

            if (self.get('reibungBeruecksichtigen')) {
                self.set('reibungBeruecksichtigenDisabled', false);
            } else {
                self.set('reibungBeruecksichtigenDisabled', true);
            }

            var systemdata = this.controllerFor('application').get('model').system.objectAt(0);
            systemdata.set('ETA_friction', self.get('reibungBeruecksichtigen'));
        }).observes('reibungBeruecksichtigen'),

        watchComboboxes: (function () {

            var self = this;

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var application = self.controllerFor('application');
            application.set('verbindungsmittelInvalid', true);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            var systemdata = this.controllerFor('application').get('model').system.objectAt(0);

            systemdata.set('FastenerPosition', this.get('einschraubtiefe'));
            systemdata.set('AngleGRAD_Fastener', this.get('einschraubwinkel'));
            systemdata.set('Var_sVM', this.get('schraubenabstand'));
            systemdata.set('TopToBottom', this.get('einschraubrichtung'));
            systemdata.set('ETA_friction_value', self.get('reibungBeruecksichtigen'));

            if (parseFloat(self.get('einschraubwinkel')) != 90) {
                self.set('showFriction', true);
            } else {
                self.set('showFriction', false);
            }
        }).observes('einschraubtiefe', 'einschraubwinkel', 'schraubenabstand', 'einschraubrichtung'),

        actions: {

            validation: function validation(value, event) {

                var self = this;

                if (!self.openFromFile) {

                    //this.setX3D(value, event);

                }

                self.validate().then(function () {
                    // all validations pass

                })['catch'](function () {
                    // any validations fail

                })['finally'](function () {

                    var validation = self.get('isValid');
                    var application = self.controllerFor('application');
                    if (validation === true) {
                        self.set('displayErrors', false);
                        application.set('systemInvalid', false);

                        var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
                        applicationdata.set('treeLoaded', false);
                        applicationdata.set('initialized', false);
                        applicationdata.set('pdfErstellt', false);

                        var downloadcenter = self.controllerFor('downloadcenter');
                        downloadcenter.set('pdfErstellt', false);

                        application.set('verbindungsmittelInvalid', true);

                        var systemdata = self.controllerFor('application').get('model').system.objectAt(0);

                        systemdata.set('sFastener', parseFloat(self.get('einschraubtiefeKopf')).toFixed(1));
                        systemdata.set('einschraubwinkelDefiniert', parseFloat(self.get('einschraubwinkelDefiniert')).toFixed(1));
                        systemdata.set('e0_VM', parseFloat(self.get('schraubenabstandDefiniert')).toFixed(1));
                        systemdata.set('AngleGRAD_Fastener', parseFloat(self.get('einschraubwinkelDefiniert')).toFixed(1));
                    } else {
                        self.set('displayErrors', true);
                        application.set('systemInvalid', true);
                    }
                });
            }

        }

    });

});