define('m14/components/las-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'las-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var x = item.get('translations').objectAt(0).get('x');
      var y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;

      if (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10 > bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 10) {
        y = y + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10;
      } else {
        y = y + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10 + (bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10) / 2;
      }

      var z = item.get('translations').objectAt(0).get('z');

      var cords = x + ' ' + (y + 0.15) + ' ' + z;

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '0 0 0 0';

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = x3d.get('bauteile').findBy('id', name);

      if (item.id.substring(0, 6) === 'gleich') {
        cords = this.getGleichlastKoordinaten();
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.viewpointHelper'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = "0 10 11 21  0 -1 1 20 -1 2 19 -1 3 18 -1 4 17 -1 5 16 -1 6 15 -1 7 14 -1 8 13 -1 9 12 -1";

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var emsvcolor = bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('rot') + ' ' + bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('gruen') + ' ' + bauteile.findBy('id', name).get('diffusecolors').objectAt(0).get('blau');

      if (x3d.get('bemessungslastAktiv') === true && name === 'gleichlast1') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.bemessungslastAktiv'),

    getGleichlastKoordinaten: function getGleichlastKoordinaten() {
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var name = this.get('name');

      var x = (2 * bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') + 2) / 100;
      var y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y');
      var z = 0;

      var p0 = -x * 5 + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x * 4 + ' ' + 0 + ' ' + z / 2;
      var p2 = -x * 3 + ' ' + 0 + ' ' + z / 2;
      var p3 = -x * 2 + ' ' + 0 + ' ' + z / 2;
      var p4 = -x + ' ' + 0 + ' ' + z / 2;
      var p5 = 0 + ' ' + 0 + ' ' + z / 2;
      var p6 = x + ' ' + 0 + ' ' + -z / 2;
      var p7 = x * 2 + ' ' + 0 + ' ' + -z / 2;
      var p8 = x * 3 + ' ' + 0 + ' ' + -z / 2;
      var p9 = x * 4 + ' ' + 0 + ' ' + -z / 2;
      var p10 = x * 5 + ' ' + 0 + ' ' + -z / 2;
      var p11 = x * 5 + ' ' + y + ' ' + z / 2;
      var p12 = x * 4 + ' ' + y + ' ' + z / 2;
      var p13 = x * 3 + ' ' + y + ' ' + z / 2;
      var p14 = x * 2 + ' ' + y + ' ' + z / 2;
      var p15 = x + ' ' + y + ' ' + z / 2;
      var p16 = 0 + ' ' + y + ' ' + -z / 2;
      var p17 = -x + ' ' + y + ' ' + -z / 2;
      var p18 = -x * 2 + ' ' + y + ' ' + -z / 2;
      var p19 = -x * 3 + ' ' + y + ' ' + -z / 2;
      var p20 = -x * 4 + ' ' + y + ' ' + -z / 2;
      var p21 = -x * 5 + ' ' + y + ' ' + -z / 2;

      return p0 + ' ' + p1 + ' ' + p2 + ' ' + p3 + ' ' + p4 + ' ' + p5 + ' ' + p6 + ' ' + p7 + ' ' + p8 + ' ' + p9 + ' ' + p10 + ' ' + p11 + ' ' + p12 + ' ' + p13 + ' ' + p14 + ' ' + p15 + ' ' + p16 + ' ' + p17 + ' ' + p18 + ' ' + p19 + ' ' + p20 + ' ' + p21;
    },

    cones: (function () {
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var name = this.get('name');

      var item = bauteile.findBy('id', name);
      var cones = new Array();
      var c0, c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, x, z;
      var y = 0.1;

      x = (2 * bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') + 2) / 100;
      z = 0;

      c0 = { id: "lastCone0", translation: -x * 5 + ' ' + y + ' ' + z };
      c1 = { id: "lastCone1", translation: -x * 4 + ' ' + y + ' ' + z };
      c2 = { id: "lastCone2", translation: -x * 3 + ' ' + y + ' ' + z };
      c3 = { id: "lastCone3", translation: -x * 2 + ' ' + y + ' ' + z };
      c4 = { id: "lastCone4", translation: -x + ' ' + y + ' ' + z };
      c5 = { id: "lastCone5", translation: 0 + ' ' + y + ' ' + z };
      c6 = { id: "lastCone6", translation: x + ' ' + y + ' ' + z };
      c7 = { id: "lastCone7", translation: x * 2 + ' ' + y + ' ' + z };
      c8 = { id: "lastCone8", translation: x * 3 + ' ' + y + ' ' + z };
      c9 = { id: "lastCone9", translation: x * 4 + ' ' + y + ' ' + z };
      c10 = { id: "lastCone10", translation: x * 5 + ' ' + y + ' ' + z };

      cones.push(c0, c1, c2, c3, c4, c5, c6, c7, c8, c9, c10);

      return cones;
    }).property('model.firstObject.transformHelper')

  });

  exports['default'] = Transform;

});