define('m14/models/verstaerkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var verstaerkung = DS['default'].Model.extend({
		nBauteile: DS['default'].attr('string'),
		VerstaerkungHolz: DS['default'].attr('boolean'),
		MatKeyVerstaerkungstraeger: DS['default'].attr('string'),
		FKLKeyVerstaerkungstraeger: DS['default'].attr('string'),
		breite_ReI: DS['default'].attr('string'),
		hoehe_ReI: DS['default'].attr('string'),
		Verstaerkungstraeger_vorgebohrt: DS['default'].attr('boolean'),

		Flachstahl: DS['default'].attr('boolean'),
		f_yk: DS['default'].attr('string'),
		Bezeichnung: DS['default'].attr('string'),
		ProfilName: DS['default'].attr('string'),
		profilHoehe: DS['default'].attr('string'),
		profilBreite: DS['default'].attr('string')
	});

	verstaerkung.reopenClass({
		FIXTURES: [{
			id: 1,
			nBauteile: "1",
			VerstaerkungHolz: true,
			MatKeyVerstaerkungstraeger: "0",
			FKLKeyVerstaerkungstraeger: "5",
			breite_ReI: "0.0",
			hoehe_ReI: "0.0",
			Verstaerkungstraeger_vorgebohrt: false,

			Flachstahl: false,
			f_yk: "23.5",
			Bezeichnung: "23.5",
			ProfilName: "U 120",
			profilHoehe: "0.0",
			profilBreite: "0.0"
		}]
	});

	exports['default'] = verstaerkung;

});