define('m14/models/geometrie', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var geometrie = DS['default'].Model.extend({

		MatKeyBestandstraeger: DS['default'].attr('string'),
		FKLKeyBestandstraeger: DS['default'].attr('string'),
		TimberElement1_b: DS['default'].attr('string'),
		TimberElement1_h: DS['default'].attr('string'),
		Bestandstraeger_vorgebohrt: DS['default'].attr('boolean'),
		systemlaenge: DS['default'].attr('string')

	});

	geometrie.reopenClass({
		FIXTURES: [{
			id: 1,
			MatKeyBestandstraeger: "0",
			FKLKeyBestandstraeger: "5",
			TimberElement1_b: "20",
			TimberElement1_h: "20",
			Bestandstraeger_vorgebohrt: false,

			systemlaenge: "0.5"
		}]
	});

	exports['default'] = geometrie;

});