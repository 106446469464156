define('m14/components/transfor-m', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'transfor-m',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    center: (function () {
      var values = '0 0 0';

      return values;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);
      var values = '0 0 0';
      var x = 0;
      var y = 0;
      var z = 0;
      var yVerschiebung;

      x = item.get('translations').objectAt(0).get('x');
      y = item.get('translations').objectAt(0).get('y');
      z = item.get('translations').objectAt(0).get('z');

      switch (name) {
        case 'lager1':
          x = x * (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          break;
        case 'lager12':
          x = x * (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          break;
        case 'lager2':
          x = x * (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          z = z * (item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20);
          if (x3d.get('istProfilstahl') && x3d.get('stahlVerstaerkung')) {
            y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('y') / 20;
          } else {
            y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 20;
          }
          break;
        case 'lager22':
          x = x * (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          z = z * (item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20);
          if (x3d.get('istProfilstahl') && x3d.get('stahlVerstaerkung')) {
            y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('y') / 20;
          } else {
            y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 20;
          }
          break;
        case 'lager23':
          x = x * (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          z = z * (item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20);
          if (x3d.get('istProfilstahl') && x3d.get('stahlVerstaerkung')) {
            y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('y') / 20;
          } else {
            y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 20;
          }
          break;
        case 'lager24':
          x = x * (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          z = z * (item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20);
          if (x3d.get('istProfilstahl') && x3d.get('stahlVerstaerkung')) {
            y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('y') / 20;
          } else {
            y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 20;
          }
          break;
        case 'material1':
          x = x * (item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          y = y * bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          break;
        case 'material12':
          x = x * (item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          y = y * bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          break;
        case 'material2':
          x = x * (item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 20;
          z = z * (item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20);
          break;
        case 'material22':
          x = x * (item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 20;
          z = z * (item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20);
          break;
        case 'material23':
          x = x * (item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 20;
          z = z * (item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20);
          break;
        case 'material24':
          x = x * (item.get('boxsizes').objectAt(0).get('x') / 20 + 0.1);
          y = y * (bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 20 - bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 20;
          z = z * (item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20);
          break;
      }

      values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var values = '0 0 0 0';

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getOutline: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (bauteile.findBy('id', name).get('typ') === 'box') {
        istVolumen = true;
      }
      return istVolumen;
    }).property(''),

    masskette2Typ: (function () {
      var value = 'hoehe';
      var name = this.get('name');

      if (name === 'auflager') {
        value = 'laenge';
      }

      return value;
    }).property(''),

    ueberstand: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (name === 'auflager' && bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager')) {
        istVolumen = true;
      }

      return istVolumen;
    }).property('model.firstObject.transformHelper'),

    holz: (function () {
      var value = true;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);

      if (name.substring(0, 5) === 'lager' || x3d.get('stahlVerstaerkung') === true && (name === 'material2' || name === 'material22' || name === 'material23' || name === 'material24')) {
        value = false;
      }

      return value;
    }).property('model.firstObject.stahlVerstaerkung'),

    abstaendeAnzeigen: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var value = false;

      if (x3d.get('abstaendeAnzeigen') === true && name.substring(0, 5) !== 'lager') {
        value = true;
      }

      return value;
    }).property('model.firstObject.abstaendeAnzeigen'),

    ansichtFront: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtOben: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint')

  });

  exports['default'] = Transform;

});