define('m14/components/schraube-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schrauben = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraube-n',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var values = '0 0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    schraubenListe: (function () {
      var self = this;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var count = 0;
      // var schrListe = Ember.Set.create();
      var schrListe = new Array();

      bauteile.forEach(function (item) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
          count++;
          if (x3d.get('ergebnisGeladen') === false && x3d.get('zweiseitigeVerstaerkung') === false && count > 4) {
            return;
          }
          schrListe.push(item);

          if (x3d.get('ergebnisGeladen') === true) {
            var rVektor = item.get('richtungsvektor').objectAt(0);

            var yWinkel = self.getWinkel({ x: rVektor.get('x'), y: 0, z: rVektor.get('z') }, { x: 0, y: 0, z: 1 });
            var zWinkel = self.getWinkel({ x: rVektor.get('x'), y: rVektor.get('y'), z: rVektor.get('z') }, { x: 0, y: 1, z: 0 });

            x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('y', yWinkel);
            x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('z', zWinkel);
          }
        }
      });

      if (x3d.get('ergebnisGeladen') === true) {
        this.setAbstaende(schrListe);
      }

      return schrListe.toArray();
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var winkel = vektor.z / Math.abs(vektor.z) * Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },

    setAbstaende: function setAbstaende(schrliste) {
      var self = this;

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var xPosListe = [];
      var zPosListe = [];

      var xPosMax = 0,
          xPosMin = 0,
          zPosMax = 0,
          zPosMin = 0;

      var schraubenLaenge = 0;
      var einschraubWinkel = 0;

      var werteGesetzt = false;

      schrliste.forEach(function (item) {
        var xVerschiebung = item.get('punkt').objectAt(0).get('x');
        var zVerschiebung = item.get('punkt').objectAt(0).get('z');

        if (xPosListe.indexOf(xVerschiebung) === -1) {
          xPosListe.push(xVerschiebung);
        }

        if (zPosListe.indexOf(xVerschiebung) === -1) {
          zPosListe.push(zVerschiebung);
        }

        if (werteGesetzt === false) {
          var yKopfPos = Number(item.get('punkt').objectAt(0).get('y')) + Number(item.get('richtungsvektor').objectAt(0).get('y')) * Number(item.get('screwProperties').objectAt(0).get('length'));
          var y = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) - yKopfPos;
          x3d.set('deltaY', y);
          werteGesetzt = true;
        }
      });

      xPosListe.sort(function (a, b) {
        return a - b;
      });
      zPosListe.sort(function (a, b) {
        return b - a;
      });

      zPosMax = zPosListe[0];
      zPosMin = zPosListe[zPosListe.length - 1];

      x3d.set('zPosMax', zPosMax);
      x3d.set('zPosMin', zPosMin);

      var m1h = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y'));
      var abstaende = [0.1 + 1.5 * 0.25 * m1h, 0.1 + 1.5 * 0.75 * m1h];
      var aGruppe = Number(x3d.get('aGruppe'));

      x3d.set('xPosMin', abstaende[0]);
      x3d.set('xPosMax', abstaende[1]);

      schrliste.forEach(function (item, index) {
        var xOld = Number(item.get('punkt').objectAt(0).get('x'));
        var xNew = 0;

        if (xPosListe.length > 2) {

          switch (xPosListe.indexOf(xOld)) {
            case 0:
              xNew = abstaende[0] - aGruppe / 2;
              break;
            case 1:
              xNew = abstaende[0] + aGruppe / 2;
              break;
            case 2:
              xNew = abstaende[1] - aGruppe / 2;
              break;
            case 3:
              xNew = abstaende[1] + aGruppe / 2;
              break;
          }
        } else {
          xNew = abstaende[xPosListe.indexOf(xOld)];
        }

        item.get('translations').objectAt(0).set('x', xNew);
      });

      x3d.set('transformHelper', !x3d.get('transformHelper'));
    },

    schraubenabstaende1: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand1') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand1', 'model.firstObject.schraubenlisteAktualisiert'),

    schraubenabstaende2: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand2') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand2', 'model.firstObject.schraubenlisteAktualisiert'),

    gekreuzteSchrauben: (function () {
      if (this.get('model').objectAt(0).get('schraubenGekreuzt') && !this.get('model').objectAt(0).get('ergebnisGeladen')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenGekreuzt', 'model.firstObject.ergebnisGeladen'),

    ergebnisGeladen: (function () {
      return this.get('model').objectAt(0).get('ergebnisGeladen');
    }).property('model.firstObject.ergebnisGeladen'),

    abstaendeAnzeigenUndergebnisGeladen: (function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (this.get('model').objectAt(0).get('ergebnisGeladen') && this.get('model').objectAt(0).get('abstaendeAnzeigen') && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.ergebnisGeladen', 'model.firstObject.abstaendeAnzeigen', 'model.firstObject.lastViewpoint'),

    groupTranslation: (function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var schraube1 = bauteile.findBy('id', 'schraubeStandard1');

      var yMaterial1 = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y'));
      var zMaterial1 = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z'));

      var a = 0;

      if (yMaterial1 / 2 > zMaterial1 / 2) {
        a = zMaterial1 / 2;
      } else {
        a = yMaterial1 / 2;
      }

      var x = 0,
          y = 0,
          z = 0;

      x = Number(schraube1.get('screwProperties').objectAt(0).get('diameter')) / 10 + 0.05;

      var delta = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) / 2 + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')) - Number(schraube1.get('translations').objectAt(0).get('y'));

      y = Number(schraube1.get('translations').objectAt(0).get('y')) / 10 + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')) / 10 + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) / 20 + delta / 10;

      var values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    ergebnisVerschiebung: (function () {

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var schraube1 = bauteile.findBy('id', 'schraubeStandard1');

      var xPosMax = Number(x3d.get('xPosMax'));
      var xPosMin = Number(x3d.get('xPosMin'));

      var x = 2 * (xPosMin + (xPosMax - xPosMin) / 2);

      // var values = 0 +' '+ 0 +' '+ 0;
      var values = -x / 10 + ' ' + 0 + ' ' + 0;

      return values;
    }).property('model.firstObject.transformHelper'),

    getMassketteHoehe: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') && x3d.get('abstaendeAnzeigen') && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.ergebnisGeladen', 'model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    schraubenpaare: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);

      return x3d.get('schraubenGekreuzt');
    }).property('model.firstObject.ergebnisGeladen')

  });

  exports['default'] = Schrauben;

});