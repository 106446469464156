define('m14/models/auflager', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var auflager = DS['default'].Model.extend({

		bLager: DS['default'].attr('string'),
		lLager: DS['default'].attr('string'),
		LagerTyp: DS['default'].attr('string'),
		LagerText: DS['default'].attr('string'),
		dl_Lager: DS['default'].attr('string'),
		L1: DS['default'].attr('string'),
		Streckenlast: DS['default'].attr('boolean'),
		Einzellast: DS['default'].attr('boolean')
	});

	auflager.reopenClass({
		FIXTURES: [{
			id: 1,
			bLager: "0",
			lLager: "0",
			LagerTyp: "0",
			LagerText: "0",
			dl_Lager: "0",
			L1: "0",
			Streckenlast: false,
			Einzellast: false
		}]
	});

	exports['default'] = auflager;

});