define('m14/models/schraubenausrichtung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var schraubenausrichtung = DS['default'].Model.extend({

		AngleScrewPlane_GRAD: DS['default'].attr('string'),
		SchraubenKreuz: DS['default'].attr('boolean'),
		WinkelScheibe: DS['default'].attr('boolean')
	});

	schraubenausrichtung.reopenClass({
		FIXTURES: [{
			id: 1,
			AngleScrewPlane_GRAD: "45.0",
			SchraubenKreuz: false,
			WinkelScheibe: false
		}]
	});

	exports['default'] = schraubenausrichtung;

});