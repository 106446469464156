define('m14/components/viewpoin-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Viewpoint = Ember['default'].Component.extend({
    tagName: 'OrthoViewpoint',
    attributeBindings: ['orientation', 'position', 'fieldofview', 'centerofrotation', 'description', 'set_bind', 'jump', 'retainUserOffsets'],
    centerofrotation: "0 0 0",
    description: 'camera',
    jump: true,
    retainUserOffsets: false,

    fieldofview: (function () {
      var values = '-5, -5, 5, 5';
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');

      return values;
    }).property(''),

    orientation: (function () {

      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      values = item.get('orientations').objectAt(0).get('x') + " " + item.get('orientations').objectAt(0).get('y') + " " + item.get('orientations').objectAt(0).get('z') + " " + item.get('orientations').objectAt(0).get('winkel');

      return values;
    }).property(''),

    position: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var x = item.get('positions').objectAt(0).get('x');
      var y = item.get('positions').objectAt(0).get('y');
      var z = item.get('positions').objectAt(0).get('z');

      values = x + " " + y + " " + z;

      return values;
    }).property(''),

    //set_bind wird in bower_components/viewpointmanagement/vpmanagement.js gesetzt
    set_bind: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var value = '';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      value = item.get('cameraProperties').objectAt(0).get('setBind');

      return value;
    }).property('')

  });

  exports['default'] = Viewpoint;

});