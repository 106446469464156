define('m14/models/schraube', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    typ: DS['default'].attr('string'),
    cylinders: DS['default'].hasMany('cylinder', { async: true }),
    cones: DS['default'].hasMany('cone', { async: true }),
    appearances: DS['default'].hasMany('appearance', { async: true }),
    translations: DS['default'].hasMany('translation', { async: true }),
    rotations: DS['default'].hasMany('rotation', { async: true }),
    diffuseColors: DS['default'].hasMany('diffuseColor', { async: true })
  });

});