define('m14/models/verbundstellen', ['exports', 'ember-data'], function (exports, DS) {

    'use strict';

    var verbundstellen = DS['default'].Model.extend({
        RichtungVH: DS['default'].attr('boolean'),
        AngleFastenerGRAD: DS['default'].attr('string'),
        schraubenpaar: DS['default'].attr('boolean')
    });

    verbundstellen.reopenClass({
        FIXTURES: [{
            id: 1,
            RichtungVH: true,
            AngleFastenerGRAD: "90",
            schraubenpaar: false
        }]
    });

    exports['default'] = verbundstellen;

});