define('m14/models/m06default', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),
    transformHelper: DS['default'].attr(''),
    viewpointHelper: DS['default'].attr(''),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkel: DS['default'].attr('number', { defaultValue: 90 }),
    vWinkel: DS['default'].attr('number', { defaultValue: 0 }),
    bemessungslast: DS['default'].attr('number', { defaultValue: 0 }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'standardSchraube' }),

    htbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    hthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ntbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    nthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    bemessungslastAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    htBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    htHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    ntBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    ntHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    bemessungslastEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    istHt: DS['default'].attr('boolean', { defaultValue: true }),
    schraubenGekreuzt: DS['default'].attr('boolean', { defaultValue: true }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 1.6;
      } }),
    startDistance: DS['default'].attr('number', { defaultValue: 0 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 0 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    randAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    randAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand2: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: false }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),

    istGitterModell: DS['default'].attr('boolean', { defaultValue: false })

  });

  exports['default'] = X3D;

});